import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import MenubarComponent from '../components/MenubarComponent'
import FooterComponent from '../components/FooterComponent'

export default function About({ data }) {
  const image = getImage(data.file.childImageSharp.gatsbyImageData)

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'pl',
        }}
      >
        <title>{data.site.siteMetadata.title} - O NAS</title>
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <meta name="author" content={data.site.siteMetadata.author} />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta
          property="og:description"
          content={data.site.siteMetadata.description}
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content={data.site.siteMetadata.url} />
        <link rel="canonical" href={data.site.siteMetadata.url} />
      </Helmet>
      <div className="overflow-hidden flex">
        <MenubarComponent active="about" />
      </div>

      <section className="mt-24">
        <div className="mx-auto px-6 md:px-8 xl:px-16 max-w-screen-2xl flex-no-wrap flex gap-8 md:gap-16 flex-col my-16">
          <GatsbyImage
            className="h-48 md:h-72 xl:h-80 rounded-sm overflow-hidden"
            image={image}
            alt="m-auto plac"
          />

          <div className="grid grid-cols-1 xl:grid-cols-2 gap-24">
            <div>
              <div className="mb-16">
                <div className="header-text-decorator mb-8">
                  <div className="ml-4">
                    <h2 className="blue-dark-color text-2xl xl:text-3xl font-bold">
                      O NAS
                    </h2>
                    <h3 className="text-gray-500 text-sm text-justify mt-1">
                      Informację o firmie
                    </h3>
                  </div>
                </div>
                <h3 className="text-gray-600 text-lg text-justify mb-8 description-main">
                  Motoryzacja to nasza pasja od dzieciństwa. Firmę prowadzimy od
                  2013 roku. Z roku na rok posiadamy coraz większe doświadczenie
                  i grono zadowolonych klientów. Każdy z naszych samochodów
                  indywidualnie sprawdzamy, odbywamy jazde próbną, mierzymy
                  grubości lakieru oraz przeprowadzamy diagnostykę komputerową.
                  Auta sprowadzamy w większosci z Niemiec od osób prywatnych.
                  Poza handlem samochami zajmujemy się rownież{' '}
                  <b>pomocą drogową</b> oraz{' '}
                  <b>wypożyczalnią Autolawet i lawet.</b>
                </h3>
              </div>

              <div className="header-text-decorator mb-8">
                <div className="ml-4">
                  <h2 className="blue-dark-color text-2xl xl:text-3xl font-bold">
                    KONTAKT
                  </h2>
                  <h3 className="text-gray-500 text-sm text-justify mt-1">
                    Zapraszamy do kontaktu
                  </h3>
                </div>
              </div>
              <div className="text-gray-600 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-10 text-lg md:text-body md:text-xl">
                <div className=" grid gap-1 grid-cols-1">
                  <h2 className="text-black font-bold">Dane kontaktowe</h2>
                  <h3 className="text-lg">M-Auto S.C.</h3>
                  <h3 className="text-lg">Zebrzydowa 242</h3>
                  <h3 className="text-lg">59-730 Nowogrodziec</h3>
                  <h3 className="text-lg">NIP: 6121862270</h3>
                </div>

                <div className=" grid gap-1 grid-cols-1">
                  <h2 className="text-black font-bold">Godziny otwarcia</h2>
                  <h3 className="text-lg">Pon-Pt: 9:00-18:00</h3>
                  <h3 className="text-lg">Sobota: 10:00-15:00</h3>
                  <h3 className="text-lg">Niedziela: 10:00-14:00</h3>
                </div>

                <div className=" grid gap-1 grid-cols-1">
                  <h2 className="text-black font-bold">Kontakt</h2>
                  <h3 className="text-lg">Tel.: +48 669 660 446</h3>
                  <h3 className="text-lg">Tel.: +48 725 730 436</h3>
                  <h3 className="text-lg">pthumauto@gmail.com</h3>
                </div>
              </div>
            </div>

            <div className="grid gap-2 grid-cols-2 md:grid-cols-4 xl:grid-cols-2 auto-rows-max">
              <StaticImage
                className="rounded-sm overflow-hidden"
                src="../assets/images/about_us_1.jpeg"
                alt="M-Auto plac"
                layout="fullWidth"
                placeholder="blurred"
                aspectRatio={1 / 1}
              />
              <StaticImage
                className="rounded-sm overflow-hidden"
                src="../assets/images/about_us_2.jpeg"
                alt="M-Auto plac"
                layout="fullWidth"
                placeholder="blurred"
                aspectRatio={1 / 1}
              />
              <StaticImage
                className="rounded-sm overflow-hidden"
                src="../assets/images/about_us_3.jpeg"
                alt="M-Auto plac"
                layout="fullWidth"
                placeholder="blurred"
                aspectRatio={1 / 1}
              />
              <StaticImage
                className="rounded-sm overflow-hidden"
                src="../assets/images/about_us_4.jpeg"
                alt="M-Auto plac"
                layout="fullWidth"
                placeholder="blurred"
                aspectRatio={1 / 1}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="header-text-decorator mb-5">
              <div className="ml-4">
                <h2 className="blue-dark-color text-2xl xl:text-3xl font-bold">
                  MAPA
                </h2>
                <h3 className="text-gray-500 text-sm text-justify mt-1">
                  Sprawdź jak do nas dojechać
                </h3>
              </div>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9993.945245420613!2d15.3768251!3d51.228538!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xed2b11048fab4dfc!2sM-AUTO%20S.C.!5e0!3m2!1spl!2sde!4v1641499533306!5m2!1spl!2sde"
              title="mapa jak dojechać do m-auto"
              width="100%"
              height="400px"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </section>
      <FooterComponent />
    </>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        url
        title
        author
        keywords
        description
      }
    }
    file(relativePath: { eq: "about_header.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
